import React from 'react'

export default function Lsb() {
  return (
      <div className="left-sidebar">
          <div className="sidebar-header d-flex align-items-center justify-content-between">
              <img src="./assets/images/logo.png" alt="Logo"/>
              <span className="designation">Portfolio Manager & Data Science</span>
          </div>
          <img className="me" src="./assets/images/me.jpg" alt="Me"/>
          <h2 className="email">handielgongora@gmail.com</h2>
          <h2 className="address">Base in Panama, PA</h2>
          <p className="copyright">&copy; Handiel Luis Góngora Quevedo</p>
          <ul className="social-profile d-flex align-items-center flex-wrap justify-content-center">
              <li>
                  <a href="https://twitter.com/"><i className="lab la-twitter"></i></a>
              </li>
              <li>
                  <a href="https://dribble.com/"><i className="lab la-youtube"></i></a>
              </li>
              <li>
                  <a href="https://instagram.com/"><i className="lab la-instagram"></i></a>
              </li>
              <li>
                  <a href="https://github.com/"><i className="lab la-facebook"></i></a>
              </li>
          </ul>
          <a href="#contact" className="theme-btn">
              <i className="las la-envelope"></i> Contact me!
          </a>
      </div>
  )
}   
