import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Contact() {
    useEffect(() => {
        AOS.init({ duration: 1300 });
    }, []);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.name || !formData.email || !formData.message) {
            setError('Full name, email, and message are required.');
            return;
        }

        // Utiliza la variable de entorno con el prefijo REACT_APP_
        const domain = process.env.REACT_APP_DOMAIN;

        if (!domain) {
            setError('API domain is not set. Please check the environment variables.');
            return;
        }

        try {
            const response = await fetch(`${domain}/api/contact/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Error:', errorData);
                setError(`Failed to send the message. Server responded with status ${response.status}.`);
            } else {
                setSuccess('Your message was sent successfully.');
                setFormData({ name: '', email: '', phone: '', subject: '', message: '' });
                setError('');
            }
        } catch (error) {
            console.error('Error:', error);
            setError('An error occurred. Please try again.');
        }
    };

    return (
        <section className="contact-area page-section scroll-content" id="contact">
            <div className="custom-container">
                <div className="contact-content content-width">
                    <div className="section-header">
                        <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                            <i className="las la-dollar-sign"></i> contact
                        </h4>
                        <h1 className="scroll-animation" data-aos='fade-up'>Let's Work <span>Together!</span></h1>
                    </div>
                    <h3 className="scroll-animation" data-aos='fade-up'>handielgongora@gmail.com</h3>
                    <p id="required-msg">* Marked fields are required to fill.</p>

                    <form className="contact-form scroll-animation" data-aos='fade-up' onSubmit={handleSubmit}>
                        {error && <div className="alert alert-danger">{error}</div>}
                        {success && <div className="alert alert-success">{success}</div>}
                        <div className="row">
                            <div className="col-md-6">
                                <div className="input-group">
                                    <label htmlFor="name">Full Name <sup>*</sup></label>
                                    <input
                                        type="text"
                                        name="name"
                                        id="full-name"
                                        placeholder="Your Full Name"
                                        value={formData.name}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="input-group">
                                    <label htmlFor="email">Email <sup>*</sup></label>
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        placeholder="Your email address"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="input-group">
                                    <label htmlFor="phone">Phone <span>(optional)</span></label>
                                    <input
                                        type="text"
                                        name="phone"
                                        id="phone-number"
                                        placeholder="Your phone number"
                                        value={formData.phone}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="input-group">
                                    <label htmlFor="subject">Subject <span>(optional)</span></label>
                                    <select
                                        name="subject"
                                        id="subject"
                                        value={formData.subject}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select a subject</option>
                                        <option value="Collaboration">Collaboration</option>
                                        <option value="Academy">Academy</option>
                                        <option value="Project">Project</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="input-group">
                                    <label htmlFor="message">Message <sup>*</sup></label>
                                    <textarea
                                        name="message"
                                        id="message"
                                        placeholder="Write your message here ..."
                                        value={formData.message}
                                        onChange={handleChange}
                                    ></textarea>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="input-group submit-btn-wrap">
                                    <button className="theme-btn" type="submit">Send Message</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
}
